import React from "react";

const CustomerAxeptiaMan = () => (
  <div className="svg_responsive">
    <svg viewBox="0 0 783 571" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Customers">
        <path
          id="Ground"
          opacity="0.2"
          d="M391.5 546C593.912 546 758 539.732 758 532C758 524.268 593.912 518 391.5 518C189.088 518 25 524.268 25 532C25 539.732 189.088 546 391.5 546Z"
          fill="#B8B9BA"
        />
        <g id="Characters / Man_2">
          <path
            className="animated pulse infinite"
            id="Arm_9_"
            d="M576.856 189.349C560.062 187.64 544.232 182.783 529.577 173.922C520.967 168.744 511.179 161.964 503.424 155.612C490.161 144.669 468.34 122.623 450.156 116.697C448.391 116.11 446.84 117.979 447.695 119.58C450.744 125.132 456.092 132.178 462.029 132.178C477.057 132.178 483.154 151.875 495.562 162.231C516.848 179.954 542.574 196.982 571.24 199.224C577.07 199.758 576.856 189.349 576.856 189.349Z"
            fill="#D4A79A"
          />
          <g id="Head_7_">
            <path
              id="Neck_6_"
              d="M580.011 166.608L580.225 190.896L598.891 199.063C598.891 199.063 588.194 181.875 590.815 167.088C593.435 152.302 580.011 166.608 580.011 166.608Z"
              fill="#D4A79A"
            />
            <path
              id="Face_7_"
              d="M565.25 116.27C565.25 116.27 560.864 121.555 564.18 138.53C567.496 155.505 573.379 170.078 578.513 169.331C592.633 167.142 597.981 157.48 603.222 149.259C609.64 139.117 598.944 113.227 598.944 113.227L588.033 106.715L565.25 116.27Z"
              fill="#EAC2AB"
            />
            <path
              id="Hair_7_"
              d="M550.489 111.146C547.547 101.163 550.542 89.6332 559.313 83.7079C560.329 83.0139 561.453 82.4268 562.629 82.4268C568.084 82.4801 565.464 94.2773 574.502 93.53C580.385 93.0495 585.038 88.8325 590.868 88.2986C598.195 87.5513 603.918 91.4481 608.25 96.9997C609.533 98.6545 618.572 111.893 613.919 112.8C628.466 109.971 614.614 144.936 611.352 149.633C608.785 153.37 603.918 155.131 599.8 153.904C601.244 150.487 602.634 146.964 603.062 143.227C603.436 139.491 609.213 122.783 590.868 118.299C577.872 115.096 564.555 129.562 553.163 116.484C551.986 115.042 551.077 113.227 550.489 111.146Z"
              fill="#333333"
            />
            <path
              id="Ear_5_"
              d="M603.597 130.95C603.597 130.95 608.999 125.345 608.036 134.206C607.073 143.067 602.313 144.668 602.313 144.668L603.597 130.95Z"
              fill="#EAC2AB"
            />
          </g>
          <g id="Leg_27_">
            <path
              id="Skin_14_"
              d="M630.338 471.199L641.516 500.559L651.731 497.836L638.2 467.89C638.253 467.89 632.477 468.584 630.338 471.199Z"
              fill="#D4A79A"
            />
            <path
              id="Shoe_14_"
              opacity="0.9"
              d="M615.363 527.943C615.738 528.53 619.321 528.316 619.856 528.316C625.418 528.423 634.029 528.797 638.788 525.594C643.441 522.444 646.49 517.053 650.608 513.263C653.496 510.594 657.24 508.992 660.663 507.124C664.46 505.042 656.759 487.8 652.159 490.576C648.415 492.818 650.287 499.117 640.767 498.263C632.638 497.569 637.772 509.153 628.359 516.092C620.016 522.177 611.405 521.27 615.363 527.943Z"
              fill="#858687"
            />
            <path
              id="Leg_28_"
              opacity="0.9"
              d="M599.479 362.729C605.201 390.968 615.844 416.911 631.033 441.039C638.467 452.836 641.088 456.306 648.736 467.889C659.647 484.437 630.178 494.58 621.674 497.089C616.112 498.743 607.768 498.85 607.982 494.046C608.036 492.605 608.945 491.27 609.694 489.936C616.54 477.711 609.64 464.74 604.078 453.263C587.499 418.832 584.557 410.665 571.186 374.953C566.106 361.341 563.538 315.22 562.308 300.487C561.559 291.466 569.154 254.313 584.771 264.722C594.505 271.234 590.173 294.081 589.477 303.316C589.05 309.722 588.087 316.662 589.263 323.174C591.831 336.519 596.805 349.384 599.479 362.729Z"
              fill="#858687"
            />
          </g>
          <g id="Leg_25_">
            <path
              id="Skin_13_"
              d="M595.361 482.836L588.408 514.438L598.73 517.854L604.025 484.437C604.025 484.437 598.677 481.768 595.361 482.836Z"
              fill="#EAC2AB"
            />
            <path
              id="Shoe_13_"
              d="M546.05 526.876C546.05 526.876 553.805 529.491 568.619 530.612C583.434 531.733 600.014 532.267 603.009 530.078C606.004 527.89 607.929 509.207 603.009 509.207C598.088 509.207 596.163 516.36 587.445 509.954C579.958 504.509 574.021 514.812 561.025 517.534C547.975 520.256 542.734 521.377 546.05 526.876Z"
              fill="#B8B9BA"
            />
            <path
              id="Leg_26_"
              d="M571.507 495.167C571.989 493.993 573.326 493.139 574.449 492.231C584.824 484.011 582.257 471.413 580.599 460.416C576.642 434.367 584.985 407.997 582.471 382C578.834 344.9 581.455 331.021 575.625 306.199C573.486 297.178 570.598 283.246 584.557 285.915C591.189 287.142 597.714 286.929 604.292 285.755C607.768 285.167 604.239 284.046 607.769 285.167C619.856 289.011 618.893 300.434 617.823 311.537C616.272 327.979 615.31 350.612 614.507 367.32C613.384 391.021 609.426 414.776 612.261 438.263C613.652 449.794 615.256 461.271 616.807 472.801C617.716 479.527 623.332 494.206 619.535 500.292C614.347 508.619 591.296 501.573 583.594 501.36C577.765 501.306 569.796 499.118 571.507 495.167Z"
              fill="#B8B9BA"
            />
          </g>

          <g id="Body_9_">
            <path
              id="Path"
              d="M567.603 234.456C571.079 220.31 567.282 196.448 577.283 185.932C578.3 184.865 579.316 183.477 580.76 183.157C606.699 177.552 608.143 190.363 613.545 199.278C618.946 208.246 611.619 215.345 614.24 225.381C617.235 236.751 616.647 248.655 616.86 260.399C616.967 267.552 616.005 271.075 616.433 278.228C616.7 283.246 616.379 286.929 617.502 291.84C618.251 295.096 623.653 306.413 622.423 309.722C621.46 312.338 616.914 311.377 614.721 311.431C606.164 311.698 603.436 311.698 595.842 307.908C588.889 304.491 562.094 312.231 554.018 311.537C550.061 311.217 559.527 291.947 561.292 284.74C565.571 267.605 563.485 258.317 565.25 246.253C565.892 242.356 566.64 238.406 567.603 234.456Z"
              fill="#153E54"
            />
            <path
              id="Path_2"
              d="M580.76 183.21C580.76 183.21 573.914 189.402 556.693 181.608L551.023 199.331C551.023 199.331 567.657 207.285 575.572 201.253C582.364 196.075 580.599 183.53 580.76 183.21Z"
              fill="#226487"
            />
          </g>

          <path
            id="Arm_8_"
            d="M613.117 258.53C609.106 269.046 558.618 278.708 534.016 276.573C527.973 276.039 521.662 275.292 517.597 280.203C520.485 281.591 522.624 280.683 525.726 281.27C522.036 284.58 518.346 287.836 514.655 291.146C513.532 292.16 512.249 293.708 512.944 295.149C513.425 296.217 514.709 296.431 515.832 296.431C522.999 296.324 528.989 290.719 535.834 288.317C544.231 285.327 550.917 286.235 559.367 284.794C616.433 274.971 629.108 273.263 625.525 238.085C622.744 211.342 617.395 207.178 609.64 198.744C608.41 197.409 606.218 196.075 604.987 197.463C604.399 198.157 604.453 199.171 604.613 200.078C605.522 205.096 608.036 209.633 610.068 214.331C615.042 225.701 616.593 249.455 613.117 258.53Z"
            fill="#EAC2AB"
          />

          <path
            id="Body_8_"
            d="M604.881 220.469C604.881 220.469 589.317 177.925 602.153 183.957C614.882 189.935 627.183 217 627.183 217L606.004 225.967L604.881 220.469Z"
            fill="#226487"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="311.833"
          y1="193.929"
          x2="346.409"
          y2="144.549"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.14" stopColor="#163F55" />
          <stop offset="0.38" stopColor="#1A4B65" />
          <stop offset="0.79" stopColor="#246588" />
        </linearGradient>
        <clipPath id="clip0">
          <rect x="313.406" y="143.1" width="40.1523" height="40.8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default CustomerAxeptiaMan;
