import React from "react";

const AxeptiaLaptopIcon = () => (
  <svg viewBox="0 0 641 264" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Axeptia Explained web v2">
      <ellipse className="" id="Shadow" cx="481" cy="246.5" rx="150" ry="7.5" fill="#B8B9BA" />
      <g id="ArrowLines">
        <path id="DebtCollection" d="M185 161L331 161" stroke="#B8B9BA" strokeDasharray="5 5" />
        <path id="ERP" d="M185 103H331" stroke="#B8B9BA" strokeDasharray="5 5" />
        <path id="CreditProvider" d="M185 46L331 46" stroke="#B8B9BA" strokeDasharray="5 5" />
      </g>

      <g className="animated slow ArrowLeftRight35 infinite" id="Arrow/DebtProvider">
        <path
          id="Union"
          d="M221.321 161.498L214.418 168.295C214.143 168.57 213.698 168.57 213.422 168.295L213.006 167.879C212.731 167.604 212.731 167.158 213.006 166.883L219 161L213.012 155.117C212.737 154.842 212.737 154.396 213.012 154.121L213.428 153.705C213.704 153.43 214.149 153.43 214.424 153.705L221.327 160.502C221.596 160.777 221.596 161.223 221.321 161.498Z"
          fill="#858687"
        />
      </g>

      <g className="animated slow ArrowLeftRight35 infinite" id="Arrow/ERP">
        <path
          id="Union_2"
          d="M176.321 103.498L169.418 110.295C169.143 110.57 168.698 110.57 168.422 110.295L168.006 109.879C167.731 109.604 167.731 109.158 168.006 108.883L174 103L168.012 97.1172C167.737 96.8418 167.737 96.3965 168.012 96.1211L168.428 95.7051C168.704 95.4297 169.149 95.4297 169.424 95.7051L176.327 102.502C176.596 102.777 176.596 103.223 176.321 103.498Z"
          fill="#858687"
        />
      </g>

      <g className="animated slow ArrowLeftRight35 infinite" id="Arrow/DebtCollection">
        <path
          id="Union_3"
          d="M227.321 46.498L220.418 53.2949C220.143 53.5703 219.698 53.5703 219.422 53.2949L219.006 52.8789C218.731 52.6035 218.731 52.1582 219.006 51.8828L225 46L219.012 40.1172C218.737 39.8418 218.737 39.3965 219.012 39.1211L219.428 38.7051C219.704 38.4297 220.149 38.4297 220.424 38.7051L227.327 45.502C227.596 45.7773 227.596 46.2227 227.321 46.498Z"
          fill="#858687"
        />
      </g>
      <g className="" id="Monitor">
        <rect id="Frame" x="331" y="10" width="300" height="185" rx="16" fill="#153E54" />
        <image href="/solution.png" x="339" y="18" height="166px" width="284px"></image>
      </g>
    </g>
  </svg>
);

export default AxeptiaLaptopIcon;
