import React from "react";

const LadyIcon = () => (
  <svg viewBox="0 0 1034 784" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="8 Work and life balance">
      <g id="8 Work and life balance_2">
        <g id="Nature">
          <path
            id="Ground"
            opacity="0.2"
            d="M517.13 767C802.275 767 1033.43 752.655 1033.43 734.96C1033.43 717.265 802.275 702.92 517.13 702.92C231.985 702.92 0.830078 717.265 0.830078 734.96C0.830078 752.655 231.985 767 517.13 767Z"
            fill="#3BAFED"
          />
          <path
            id="Cloud_1_"
            opacity="0.1"
            d="M801.292 213.76C807.142 214.01 814.932 212.72 815.612 206.91C816.092 202.8 812.352 199.53 808.912 197.21C802.122 192.63 793.952 188.36 786.082 190.64C783.052 191.52 780.352 193.31 777.302 194.12C759.612 198.78 745.972 169.27 728.492 174.66C725.632 175.54 723.152 177.31 720.462 178.62C717.082 180.26 713.392 181.17 709.702 181.84C697.532 184.04 684.922 183.76 672.862 181.05C667.402 179.82 661.952 178.08 656.352 178.29C641.712 178.83 615.252 192.54 630.672 207.81C641.672 218.69 655.742 214.54 668.542 211.65C709.632 202.34 759.342 211.98 801.292 213.76Z"
            fill="#2F89BA"
          />

          <path
            id="Cloud_3_"
            opacity="0.1"
            d="M708.858 79.7338C716.752 78.4956 727.043 73.5585 724.647 65.9412C723.91 63.5745 721.968 61.7721 719.963 60.2988C691.503 39.4846 651.153 68.3549 616.835 60.3302C606.512 57.9165 597.224 52.2113 587.043 49.2804C581.153 47.5877 574.998 46.8511 569.28 44.6098C550.234 37.1649 540.616 15.3319 522.869 5.14427C500.283 -7.8176 467.264 4.94052 459.229 29.7044C454.843 43.2148 455.814 60.6123 443.784 68.1511C429.029 77.3984 403.232 63.9977 398 85.7993C440.37 103.542 482.364 86.8338 525.751 87.7272C587.497 89.0124 647.567 89.2945 708.858 79.7338Z"
            fill="#2F89BA"
          />

          <path
            id="Cloud_4_"
            opacity="0.1"
            d="M406.18 167.281C410.17 166.661 415.37 164.161 414.17 160.311C413.79 159.111 412.82 158.201 411.8 157.461C397.41 146.941 377.01 161.531 359.65 157.481C354.43 156.261 349.74 153.371 344.58 151.901C341.6 151.051 338.49 150.671 335.6 149.541C325.97 145.781 321.11 134.751 312.13 129.601C300.71 123.051 284.02 129.491 279.96 142.011C277.74 148.841 278.23 157.631 272.15 161.441C264.69 166.111 251.65 159.341 249 170.351C270.42 179.321 291.66 170.881 313.59 171.331C344.82 171.971 375.2 172.111 406.18 167.281Z"
            fill="#2F89BA"
          />

          <g className="floating-birdleft" id="BirdLeft">
            <path
              id="Path"
              d="M284.841 345.806L295.911 343.496L288.061 352.546L284.841 345.806Z"
              fill="#153E54"
            />
            <path
              id="Path_2"
              d="M242.951 375.836C239.881 379.556 236.721 383.986 232.901 387.286C229.111 390.556 222.011 391.206 219.121 394.746C227.991 404.426 249.881 397.556 259.451 392.536C272.221 385.846 282.611 374.356 287.131 360.586C289.541 353.236 289.921 344.026 284.111 338.916C281.291 336.436 277.511 335.416 273.841 334.596C268.101 333.316 261.041 331.936 255.361 334.236C246.721 337.736 256.151 337.216 258.081 341.186C260.361 345.876 255.621 354.806 253.571 358.806C250.511 364.756 247.241 370.656 242.951 375.836Z"
              fill="#2F89BA"
            />
            <path
              id="Oval"
              d="M276.791 345.806C278.067 345.806 279.101 344.772 279.101 343.496C279.101 342.22 278.067 341.186 276.791 341.186C275.515 341.186 274.481 342.22 274.481 343.496C274.481 344.772 275.515 345.806 276.791 345.806Z"
              fill="#153E54"
            />
            <path
              id="Path_3"
              d="M238.111 347.266C232.161 345.106 225.491 343.926 220.981 339.476C218.501 337.026 216.431 333.476 212.961 333.186C212.431 333.136 211.851 333.196 211.461 333.566C210.981 334.026 210.981 334.776 211.011 335.436C211.211 339.396 211.431 343.456 213.001 347.096C214.571 350.736 217.811 353.926 221.761 354.266C222.241 354.306 222.761 354.316 223.171 354.566C224.361 355.296 223.711 357.206 222.641 358.116C221.571 359.026 220.121 359.736 219.821 361.096C219.501 362.536 220.761 363.946 222.181 364.346C223.601 364.746 225.111 364.396 226.551 364.046C226.021 366.566 225.911 369.936 228.291 370.916C230.361 371.776 233.191 370.096 234.791 371.656C235.391 372.236 235.591 373.106 235.921 373.866C237.011 376.346 239.691 377.856 242.391 378.126C245.081 378.396 247.781 377.596 250.251 376.496C256.621 373.666 263.621 367.376 258.521 360.836C253.731 354.696 245.291 349.866 238.111 347.266Z"
              fill="#153E54"
            />
          </g>
          <g className="floating-birdright" id="BirdRightTop">
            <path
              id="Path_4"
              d="M634.22 296.508L626 294.798L631.83 301.508L634.22 296.508Z"
              fill="#153E54"
            />
            <path
              id="Path_5"
              d="M665.3 318.788C667.58 321.548 669.92 324.838 672.75 327.288C675.56 329.718 680.83 330.198 682.98 332.818C676.4 340.008 660.16 334.908 653.05 331.178C643.58 326.208 635.87 317.688 632.51 307.468C630.72 302.018 630.44 295.178 634.75 291.388C636.84 289.548 639.65 288.798 642.37 288.188C646.63 287.238 651.87 286.208 656.08 287.918C662.49 290.518 655.5 290.128 654.06 293.078C652.37 296.558 655.88 303.188 657.41 306.148C659.69 310.568 662.12 314.948 665.3 318.788Z"
              fill="#2F89BA"
            />
            <path
              id="Oval_2"
              d="M640.19 296.508C641.134 296.508 641.9 295.742 641.9 294.798C641.9 293.853 641.134 293.088 640.19 293.088C639.246 293.088 638.48 293.853 638.48 294.798C638.48 295.742 639.246 296.508 640.19 296.508Z"
              fill="#153E54"
            />
            <path
              id="Path_6"
              d="M668.89 297.598C673.31 295.998 678.26 295.118 681.6 291.818C683.44 289.998 684.97 287.368 687.55 287.148C687.94 287.118 688.38 287.158 688.66 287.428C689.01 287.768 689.02 288.328 688.99 288.818C688.84 291.758 688.68 294.768 687.51 297.468C686.34 300.168 683.94 302.538 681.01 302.788C680.65 302.818 680.27 302.828 679.96 303.018C679.07 303.558 679.56 304.978 680.36 305.648C681.15 306.318 682.23 306.848 682.45 307.858C682.69 308.928 681.76 309.978 680.7 310.268C679.64 310.558 678.52 310.308 677.46 310.048C677.85 311.918 677.94 314.418 676.17 315.148C674.63 315.788 672.54 314.538 671.34 315.698C670.9 316.128 670.75 316.768 670.5 317.338C669.69 319.178 667.7 320.298 665.7 320.498C663.7 320.698 661.7 320.108 659.87 319.288C655.14 317.188 649.95 312.518 653.74 307.668C657.3 303.108 663.56 299.528 668.89 297.598Z"
              fill="#153E54"
            />
          </g>
          <g className="floating-birdright" id="BirdRightBottom">
            <path
              id="Path_7"
              d="M750.4 417.526L738 419.626L749.8 425.826L750.4 417.526Z"
              fill="#153E54"
            />
            <path
              id="Path_8"
              d="M805.94 431.636C810.62 434.236 815.69 437.546 820.98 439.416C826.23 441.266 833.85 439.056 838.27 441.546C833.02 455.166 807.56 456.926 795.62 455.616C779.69 453.866 764.27 446.186 754 433.756C748.52 427.126 744.39 417.736 748.34 410.096C750.26 406.386 753.76 403.796 757.22 401.466C762.64 397.806 769.39 393.516 776.21 393.596C786.58 393.716 776.6 396.996 776.21 401.896C775.75 407.676 784.28 415.006 788.03 418.306C793.63 423.236 799.41 428.006 805.94 431.636Z"
              fill="#2F89BA"
            />
            <path
              id="Oval_3"
              d="M757.8 414.446C759.219 414.446 760.37 413.295 760.37 411.876C760.37 410.456 759.219 409.306 757.8 409.306C756.381 409.306 755.23 410.456 755.23 411.876C755.23 413.295 756.381 414.446 757.8 414.446Z"
              fill="#153E54"
            />
            <path
              id="Path_9"
              d="M799.38 400.096C804.67 395.446 811.1 391.516 813.95 385.086C815.52 381.546 816.23 377.036 819.7 375.316C820.23 375.056 820.86 374.876 821.41 375.106C822.09 375.386 822.39 376.166 822.63 376.856C824.03 381.036 825.45 385.326 825.3 389.726C825.15 394.136 823.08 398.746 819.14 400.706C818.66 400.946 818.13 401.166 817.8 401.596C816.86 402.836 818.32 404.546 819.79 405.046C821.26 405.546 823.05 405.696 823.92 406.986C824.84 408.346 824.11 410.316 822.79 411.306C821.48 412.296 819.77 412.546 818.14 412.776C819.71 415.176 821.19 418.606 819.13 420.596C817.33 422.326 813.73 421.736 812.7 424.006C812.32 424.856 812.46 425.826 812.42 426.756C812.3 429.766 810.13 432.426 807.45 433.796C804.77 435.166 801.66 435.436 798.65 435.296C790.9 434.946 781.11 431.276 783.74 422.436C786.22 414.126 792.99 405.706 799.38 400.096Z"
              fill="#153E54"
            />
          </g>
        </g>
        <g id="Characters">
          <g id="Woman">
            <path
              id="Neck"
              d="M469.94 382.18L471.17 446.28L519.32 471.64L534.47 442.31L552.2 387.84L543.17 368.13L469.94 382.18Z"
              fill="#D4A79A"
            />
            <g id="Legs_1_">
              <path
                id="Skin_1_"
                d="M478.67 720.19C476.58 719.07 474.91 720.51 470.75 721.78C461.16 724.71 451.29 726.71 441.32 727.73C437.56 728.12 433.46 728.5 430.71 731.09C428.01 733.63 427.36 737.58 426.21 741.1C421.6 755.19 408.06 764.02 398.28 775.18C397.38 776.2 396.47 777.61 397.11 778.81C397.56 779.66 398.61 779.98 399.57 780.12C405.33 780.97 411.02 778.38 416.09 775.5C423.8 771.13 431.04 765.95 437.67 760.08C442.02 756.22 446.22 751.98 451.56 749.67C454.31 748.48 457.26 747.84 460.16 747.1C469.87 744.61 479.26 740.84 487.99 735.92C485.86 731.76 484 725.64 480.94 722.15C480.06 721.18 479.34 720.55 478.67 720.19Z"
                fill="#EAC2AB"
              />
              <path
                id="Skin"
                d="M506.93 723.209C509.02 722.089 510.69 723.529 514.85 724.809C524.44 727.739 534.31 729.739 544.28 730.759C548.04 731.149 552.14 731.529 554.89 734.119C557.59 736.659 558.24 740.609 559.39 744.129C564 758.219 577.54 767.049 587.32 778.209C588.22 779.229 589.13 780.639 588.49 781.839C588.04 782.689 586.99 783.009 586.03 783.149C580.27 783.999 574.58 781.409 569.51 778.529C561.8 774.159 554.56 768.979 547.93 763.109C543.58 759.249 539.38 755.009 534.04 752.699C531.29 751.509 528.34 750.869 525.44 750.129C515.73 747.639 506.34 743.869 497.61 738.949C499.74 734.789 501.6 728.669 504.66 725.179C505.54 724.189 506.26 723.569 506.93 723.209Z"
                fill="#EAC2AB"
              />
              <path
                id="Legs"
                d="M491.17 744.56C527.15 722.41 570.46 716.76 611.21 705.6C651.96 694.44 694.36 674.32 712.38 636.1C716.94 626.44 718.9 613.13 710.47 606.57C707.22 604.04 703.04 603.11 698.98 602.42C655.22 595.02 610.61 608.73 570.03 626.69C560.91 630.73 551.85 635 542.29 637.84C524.24 643.2 505.1 643.24 486.28 643.24C463.91 643.24 441.54 643.25 419.18 642.94C404.75 642.74 390.05 642.37 376.37 637.76C367.35 634.72 359.05 629.92 350.4 625.95C329.86 616.51 307.19 611.74 284.59 612.09C275.43 612.23 265.98 613.3 257.99 617.78C250 622.26 243.76 630.85 244.36 639.99C245.17 652.29 257.03 660.51 267.83 666.43C304.52 686.55 343.47 702.27 382.92 716.25C397.62 721.46 423.58 727.05 439.15 727.98C454.33 728.88 460.78 724.48 474.52 722.2C487.36 720.08 485.25 734.7 491.17 744.56Z"
                fill="#858687"
              />
              <path
                id="Shadow"
                d="M549.48 686.14C579.74 675.54 609.99 664.95 640.25 654.35C620.94 654.99 601.39 655.67 582.84 661.06C563.07 666.81 545.23 677.68 526.31 685.81C507.39 693.95 486.03 699.37 466.29 693.52C459.12 691.39 452.52 687.69 445.48 685.16C374.54 659.71 356.41 640.34 332.56 659.71C348.78 652.89 439.52 698.9 467.84 717.09C483.89 727.4 491.09 714.09 504.81 705.64C518.67 697.11 534.17 691.5 549.48 686.14Z"
                fill="#535354"
              />
            </g>
            <path
              id="Arm_1_"
              d="M473.47 443.95C473.47 443.95 434.8 506.77 403.86 561.16C396.61 573.91 387.08 585.26 375.95 594.27C367.75 600.9 358.26 605.35 348.76 609.35C345.2 610.85 339.97 614.25 336.12 614.3C331.39 614.37 328.25 610.69 324 609.16C322.17 608.5 320.18 608.36 318.28 608.76C308.03 610.89 322.05 619.78 325.03 618.01C320.48 620.7 315.26 621.69 310.3 623.32C305.34 624.95 300.28 627.49 297.5 632.19C296.19 634.4 295.6 637.61 297.37 639.41C298.37 640.42 299.82 640.67 301.18 640.85C306.56 641.57 311.98 642.01 317.41 642.17C330.74 642.57 334.64 635.5 345.29 627.94C356.29 620.13 376.19 611.72 387.18 603.93C398.76 595.72 412.27 585.99 419.01 572.54C432.18 546.27 476.93 464.51 476.93 464.51L473.47 443.95Z"
              fill="#EAC2AB"
            />
            <path
              id="Arm"
              d="M514.14 449.979C514.14 449.979 552.79 501.219 588.69 552.459C597.11 564.469 607.66 574.88 619.59 582.799C628.37 588.63 638.24 592.169 648.07 595.259C651.76 596.419 657.28 599.309 661.12 598.999C665.83 598.619 668.61 594.66 672.71 592.749C674.47 591.929 676.44 591.599 678.37 591.809C688.78 592.969 675.65 603.129 672.51 601.649C677.29 603.899 682.58 604.389 687.68 605.549C692.78 606.71 698.05 608.759 701.26 613.179C702.77 615.249 703.66 618.399 702.07 620.349C701.17 621.449 699.75 621.829 698.41 622.139C693.12 623.359 687.77 624.309 682.38 624.979C669.15 626.629 664.6 619.959 653.29 613.429C641.61 606.679 621.01 600.189 609.34 593.459C597.04 586.379 582.68 577.959 574.7 565.189C559.13 540.279 506.89 463.079 506.89 463.079L514.14 449.979Z"
              fill="#EAC2AB"
            />
            <path
              id="Body"
              d="M429.74 648.03C440.55 649.34 451.14 645.91 462.09 644.82C472.83 643.75 484.08 646.55 494.5 648.82C517.03 653.73 537.17 650.63 557.5 639.77C570.43 632.87 550.05 612.93 545.97 605.4C541.99 598.06 540.25 589.74 538.9 581.51C535 557.8 533.89 533.62 535.63 509.65C536.93 491.63 530.72 478.73 525.01 462.02C522.16 453.69 526.36 450.77 521.28 443.95C518.17 439.78 504.77 452.68 499.77 452.27C490.23 451.49 477.64 439.27 471.18 446.27C465.85 452.05 462.11 459.08 458.44 466.04C449.08 483.75 439.55 501.96 437.1 521.84C433.82 548.51 440.57 567.92 431.93 593.6C429.63 600.45 427.71 615.7 413.08 642.84C412.98 643.04 422 646.21 422.64 646.43C425.02 647.24 427.39 647.75 429.74 648.03Z"
              fill="#3BAFED"
            />
            <g id="Head">
              <path
                id="Hair_1_"
                d="M504.64 288.6C504.64 288.6 512.58 306.25 500.17 317.12C487.76 327.99 470.89 319.38 454.51 329.34C438.13 339.3 439.9 351.09 447.41 377.34C456.34 408.57 466.11 407.64 474.88 409.9C483.65 412.16 486.28 436.6 487.27 447.93C488.84 465.86 472.74 471.79 460.4 480.13C450.15 487.06 442.71 494.58 446.43 507.75C448.07 513.56 450.46 519.74 448.45 525.44C446.52 530.91 440.85 534.36 435.12 535.3C427.18 536.6 415.25 537.02 420 526.74C430.35 504.32 411.22 506.51 407.12 489.17C402.65 470.28 394.24 454.75 415.73 443.42C422.15 440.03 429.33 436.83 432.79 430.45C439.64 417.84 429.33 406 423.94 395.23C416.07 379.51 413.18 361.18 418.78 344.26C428.21 315.74 418.28 324.34 430.69 301.71C443.1 279.09 488.26 254.19 504.64 288.6Z"
                fill="#153E54"
              />
              <path
                id="Face"
                d="M459.45 321.45C459.45 321.45 439.06 334.37 444.49 367.03C449.92 399.68 479.59 429.44 489.4 428.19C516.53 424.74 519.64 405.86 530.07 390.29C542.86 371.2 523.71 321.05 523.71 321.05L503.19 308.03L459.45 321.45Z"
                fill="#EAC2AB"
              />
              <g id="Hair">
                <path
                  id="Path_10"
                  d="M489.25 309.42C489.25 309.42 502.75 313.95 514.61 332.96C526.47 351.97 539.52 360.22 530.97 384.21C527.06 395.17 523.86 404.71 519.88 414.27C514.96 426.1 511.28 438.37 509.17 451.02C507.39 461.68 512.64 464.97 519.32 471.64C532.56 484.86 533.05 503.17 544.75 515.94C552.74 524.66 573.18 534.22 584.55 526.69C595.47 519.45 562.9 524.29 569.36 493.05C575.82 461.81 578.02 448.56 559.16 439.89C540.3 431.21 549.31 428.92 559.24 409.91C569.17 390.9 543.58 391.13 553.68 358.04C565.34 319.85 554.77 277.28 497.7 289.05C440.61 300.82 489.25 309.42 489.25 309.42Z"
                  fill="#153E54"
                />
                <path
                  id="Path_11"
                  d="M475.35 345.77C491.73 335.81 487.76 327.98 500.17 317.12C512.58 306.26 504.64 288.6 504.64 288.6C488.26 254.2 443.1 279.09 430.69 301.73C418.28 324.36 428.21 315.76 418.78 344.28C412.28 363.94 419.22 383.55 423.94 395.25L444.27 396.65C433.47 355.63 463.04 353.25 475.35 345.77Z"
                  fill="#153E54"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LadyIcon;
