import React from "react";
import AxeptiaXxplainedV2 from "./axeptia-explained-v2";
import AxeptiaLaptopIcon from "./axeptia-laptop";
import CustomerAxeptiaMan from "./customer-axeptia-quote";
import CustomersIcon from "./customers-icon";
import HamburgerIcon from "./hamburger";
import LadyIcon from "./ladyIcon";

function Icon(props) {
  switch (props.symbol) {
    case "hamburger":
      return <HamburgerIcon />;
    case "ladyIcon":
      return <LadyIcon />;
    case "axeptiaMan":
      return <CustomerAxeptiaMan />;
    case "axeptiaLaptopIcon":
      return <AxeptiaLaptopIcon />;
    case "axeptiaXxplainedV2":
      return <AxeptiaXxplainedV2 />;
    case "customersIcon":
      return <CustomersIcon />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
